export const IS_DEV = window.location.hostname !== 'www.rocketlauncher.gg';

const MAIN_SOLANA_ENDPOINT = 'https://emera-upzvpf-fast-mainnet.helius-rpc.com';
const MAIN_LOOKUP_TABLE_ADDRESS = '8F7sgcZsDLHgTsYL7WzsJHKfi8KUH18dQmkTbrq3vNtK';
const MAIN_CLMM_PROGRAM_ID = 'CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK';
const MAIN_FEE_RECEIVER = '8iv4cFhk7s7SCysedKgBC2haqEfvx8HmJ75PP5pmjn1b';
const MAIN_PROGRAM_ID = 'RoCKeTyq1Wf8Co6e2x1RQCC6uxRkZrQ3mDJUEoQryhh';
const MAIN_PUMPFUN_API_URL = 'https://mainnet.instameme.pro/api/pump';
const MAIN_SOLANA_ROCKET_API_URL = 'https://mainnet.instameme.pro/api';
const MAIN_IPFS_UPLOADER_URL = 'https://mainnet.instameme.pro/upload';

const DEV_SOLANA_ENDPOINT = 'https://api.devnet.solana.com';
const DEV_LOOKUP_TABLE_ADDRESS = 'AvTJscQQjDNMfKeeGgb9VdqE4DY818c92pBwbq1K25PX';
const DEV_CLMM_PROGRAM_ID = 'devi51mZmdwUJGU9hjN27vEz64Gps7uUefqxg27EAtH';
const DEV_FEE_RECEIVER = '5TLHYfEXHmNCctnCurLiPzLkwW46gxECePQ9BTwGYfvD';
const DEV_PROGRAM_ID = 'RoCKeTyq1Wf8Co6e2x1RQCC6uxRkZrQ3mDJUEoQryhh';
const DEV_PUMPFUN_API_URL = 'https://devnet.instameme.pro/api/pump';
const DEV_SOLANA_ROCKET_API_URL = 'https://devnet.instameme.pro/api';
const DEV_IPFS_UPLOADER_URL = 'https://devnet.instameme.pro/upload';

// export const SOLANA_ENDPOINT = IS_DEV ? DEV_SOLANA_ENDPOINT : MAIN_SOLANA_ENDPOINT;
export const SOLANA_ENDPOINT = MAIN_SOLANA_ENDPOINT; // always using prod endpoint
export const LOOKUP_TABLE_ADDRESS = IS_DEV ? DEV_LOOKUP_TABLE_ADDRESS : MAIN_LOOKUP_TABLE_ADDRESS;
export const CLMM_PROGRAM_ID = IS_DEV ? DEV_CLMM_PROGRAM_ID : MAIN_CLMM_PROGRAM_ID;
export const FEE_RECEIVER = IS_DEV ? DEV_FEE_RECEIVER : MAIN_FEE_RECEIVER;
export const PROGRAM_ID = IS_DEV ? DEV_PROGRAM_ID : MAIN_PROGRAM_ID;
export const PUMPFUN_API_URL = IS_DEV ? DEV_PUMPFUN_API_URL : MAIN_PUMPFUN_API_URL;
export const IPFS_UPLOADER_URL = IS_DEV ? DEV_IPFS_UPLOADER_URL : MAIN_IPFS_UPLOADER_URL;
export const SOLANA_ROCKET_API_URL = IS_DEV
  ? DEV_SOLANA_ROCKET_API_URL
  : MAIN_SOLANA_ROCKET_API_URL;

export const TON_ROCKET_API_URL = 'https://mainnet.instameme.pro/api/ton/';

export const IPFS_GATEWAY = 'https://gateway.pinata.cloud/ipfs';

export const TOKEN_DECIMALS = 6;

export const SIGN_MESSAGE = 'By signing this message I am confirming ownership of this account';
export const ARG_DECIMAL_PLACES = 9;
export const NUM_WALLETS = 21;
export const PUMPFUN_BUMPER_AMOUNT_FEE_PCT = 1; // 1%
export const PUMPFUN_BUNDLE_FEE = 1_000_000_000; // 1 SOL
export const ROCKET_LAUNCHER_FEE_RECEIVER = 'HXf3Fy849zUQipvyz5YKiz8tmguMhjPKKneEVMCUPxRN';
