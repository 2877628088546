import React from 'react';
import { HeaderDashboard } from 'components/Headers/Dashboard';
import LeftBar from 'components/LeftBar';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import Typography from 'components/Typography';

export const DashboardLayout = ({ title = '', children }) => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <HeaderDashboard />
      <div className="flex w-full h-full flex-1">
        <LeftBar />
        <div className="overflow-auto flex flex-col flex-1 w-full py-3 px-6 sm:px-2">
          <div className="hidden sm:flex items-center justify-center sm:mt-3 sm:mb-2">
            <Typography
              className={'text-white'}
              text={title}
              variant={TYPOGRAPHY_VARIANTS.BODY_H2}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
