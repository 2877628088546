import React, { createContext, useContext, useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { TonConnect } from '@tonconnect/sdk';

import { delay } from 'helpers/delay';
import { WALLET_NAMES, WALLET_TYPES } from './constants';
import { getManifestForActualStand } from 'helpers/manifest';

export const WalletContext = createContext({
  network: null,
  signer: null,
  userAddress: '',
  connectWallet: () => {},
  disconnectWallet: () => {},
  tonConnector: {}
});

export const WalletProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState('');
  const [tonConnector] = useState(
    new TonConnect({
      manifestUrl: getManifestForActualStand()
    })
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [network, setNetwork] = useState(null);
  const [signer, setSigner] = useState(null);

  const setProvider = (provider) => {
    setNetwork(provider);
    setSigner(window[provider]);
  };

  const resetProvider = () => {
    setNetwork(null);
    setSigner(null);
  };

  const connectPhantom = async (onlyIfTrusted) => {
    try {
      const response = await window.solana.connect({ onlyIfTrusted: onlyIfTrusted === true });
      setUserAddress(response.publicKey.toString());
      setProvider(WALLET_NAMES.SOLANA);
    } catch (error) {}
  };

  const disconnectPhantom = () => {
    window.solana.disconnect();
    setUserAddress('');
  };

  const connectSolflare = async () => {
    try {
      await window.solflare.connect();
      setUserAddress(window.solflare.publicKey.toString());
      setProvider(WALLET_NAMES.SOLFLARE);
    } catch (error) {}
  };

  const disconnectSolflare = () => {
    window.solflare.disconnect();
    setUserAddress('');
  };

  const connectCoinbase = async () => {
    try {
      await window.coinbaseSolana.connect();
      setUserAddress(window.coinbaseSolana.publicKey.toString());
      setProvider(WALLET_NAMES.COINBASE_SOLANA);
    } catch (error) {}
  };

  const disconnectCoinbase = async () => {
    await window.coinbaseSolana.disconnect();
    setUserAddress('');
  };

  const connectTonkeeper = async () => {
    const walletConnectionSource = {
      jsBridgeKey: WALLET_NAMES.TONKEEPER
    };

    try {
      // Попытка подключения к кошельку
      await tonConnector.connect(walletConnectionSource);
      // Подписка на реактивные события у tonconnect sdk
      await tonConnector.onStatusChange(async (status) => {
        if (status?.provider === 'injected') {
          const account = tonConnector.account;

          if (account) {
            const publicKey = account.publicKey;
            setProvider(WALLET_NAMES.TONKEEPER);
            setUserAddress(publicKey);
          } else {
            console.log('Account information is not available yet');
          }
        }
      });
    } catch (error) {
      console.error('Connection error:', error);
    }
  };

  const disconnectTonkeeper = async () => {
    try {
      await tonConnector?.disconnect();
      setUserAddress('');
      console.log('Wallet disconnected successfully');
    } catch (error) {
      console.error('Error during disconnect:', error);
    }
  };

  const connectWallet = async (type, onlyIfTrusted) => {
    // window.solflare не успевает инициализироваться, поэтому ждем
    await delay(200);
    if (type) {
      localStorage.setItem('wallet', type);
    }
    if (window.solana && window.solana?.isPhantom && type === WALLET_TYPES.PHANTOM) {
      connectPhantom(onlyIfTrusted);
    } else if (window.solflare && window.solflare?.isSolflare && type === WALLET_TYPES.SOLFLARE) {
      connectSolflare();
    } else if (window.coinbaseSolana && type === WALLET_TYPES.COINBASE) {
      connectCoinbase();
    } else if (type === WALLET_TYPES.TONKEEPER) {
      connectTonkeeper();
    }
  };

  const disconnectWallet = (type) => {
    if (type === WALLET_TYPES.PHANTOM) {
      disconnectPhantom();
    } else if (type === WALLET_TYPES.SOLFLARE) {
      disconnectSolflare();
    } else if (type === WALLET_TYPES.COINBASE) {
      disconnectCoinbase();
    } else if (type === WALLET_TYPES.TONKEEPER) {
      disconnectTonkeeper();
    }

    localStorage.removeItem('wallet');
    resetProvider();
  };

  // Automatically try to connect to the wallet when the component mounts
  useEffect(() => {
    const wallet = localStorage.getItem('wallet');
    const load = async () => {
      if (userAddress || isInitialized) return;
      await connectWallet(wallet, true);
    };
    load().then(() => setIsInitialized(true));
  }, [userAddress, isInitialized]);

  if (!isInitialized) {
    const loader = (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
    return <React.Fragment>{loader}</React.Fragment>;
  }
  return (
    <WalletContext.Provider
      value={{
        tonConnector,
        network,
        signer,
        userAddress,
        connectWallet,
        disconnectWallet
      }}>
      {children}
    </WalletContext.Provider>
  );
};

export function useWallet() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('Missing wallet context');
  }
  const { network, signer, userAddress, connectWallet, disconnectWallet, tonConnector } = context;

  return {
    network,
    signer,
    userAddress,
    tonConnector,
    connectWallet,
    disconnectWallet
  };
}
