import React from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import MarketTableControllerTon from 'controllers/Ton/MarketTableController';

const Market = () => {
  return (
    <DashboardLayout title="Market">
      <MarketTableControllerTon />
    </DashboardLayout>
  );
};

export default Market;
