import React, { useState, useCallback, useEffect, useRef } from 'react';

import MarketTableTon from 'components/Tables/Ton/MarketTable';
import { useRequest } from 'helpers/hooks/useRequest';
import { PAGE_SIZE } from './constants';
import { TonRepository } from 'connectors/repositories/ton';

const MarketTableControllerTon = () => {
  const currentPage = useRef(0);
  const [queryParams, setQueryParams] = useState(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  const [isShowLoadingButton, setIsShowLoadingButton] = useState(true);

  const requestMapper = (prev, data) => {
    if (data?.result?.length < PAGE_SIZE) setIsShowLoadingButton(false);
    if (Object.keys(prev)?.length) {
      return { result: [...prev.result, ...data.result] };
    }

    return data;
  };

  const { data, call } = useRequest(TonRepository.getTokens, [queryParams], requestMapper);

  useEffect(() => {
    call();
  }, [queryParams]);

  const onLoadMore = useCallback(async () => {
    currentPage.current += 1;
    setQueryParams(`page=${currentPage.current}&size=${PAGE_SIZE}`);
  }, []);

  return (
    <MarketTableTon data={data} onLoadMore={onLoadMore} isShowLoadingButton={isShowLoadingButton} />
  );
};

export default MarketTableControllerTon;
