import React, { useEffect, useState } from 'react';
import { setCookie, parseCookies } from 'nookies';
import { useNavigate } from 'react-router-dom';

import ReactPortal from 'components/Portal';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';
import { BUTTON_VARIANTS } from 'components/Button/constants';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';
import { useWallet } from 'contexts/wallet';

const CookieLayout = ({ children }) => {
  const [isOpenCookiesAlert, setIsOpenCookiesAlert] = useState(true);
  const { setBlurBackground, resetBlurBackground } = useBlurBackground();
  const navigate = useNavigate();
  const { userAddress } = useWallet();

  useEffect(() => {
    if (parseCookies().acceptCookies) {
      resetBlurBackground();
      return setIsOpenCookiesAlert(false);
    }
    setBlurBackground();
    return setIsOpenCookiesAlert(true);
  }, []);

  const onAcceptCookies = () => {
    setCookie(null, 'acceptCookies', 'true');
    resetBlurBackground();
    setIsOpenCookiesAlert(false);

    if (userAddress) {
      navigate('/createToken');
    }
  };

  return (
    <React.Fragment>
      {children}
      <ReactPortal isOpen={isOpenCookiesAlert}>
        <div className="rounded-[1000px] sm:rounded-[16px] max-w-[1112px] sm:flex-col sm:max-w-max ml-auto mr-auto flex items-center py-4 px-6 cookiesAlert absolute z-50 bottom-6 right-1 left-1">
          <div className="flex flex-col">
            <Typography
              className="text-white"
              text="Our website might be using cookies to improve the website’s performance and make it more comfortable for users."
              variant={TYPOGRAPHY_VARIANTS.BODY_S}
            />
            <div className="flex flex-wrap">
              <Typography
                className="text-white"
                text="If you continue to use"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-orange-second mr-1 ml-1"
                text="rocketlauncher.gg"
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
              <Typography
                className="text-white"
                text="you agree to the use of cookies on this website."
                variant={TYPOGRAPHY_VARIANTS.BODY_S}
              />
            </div>
          </div>
          <Button
            variant={BUTTON_VARIANTS.LARGE}
            className="ml-auto sm:mr-auto sm:w-full sm:mt-3"
            text="Continue"
            onClick={onAcceptCookies}
          />
        </div>
      </ReactPortal>
    </React.Fragment>
  );
};

export default CookieLayout;
