import React, { useContext, createContext } from 'react';
import { useSnackbar } from 'notistack';
import { Connection } from '@solana/web3.js';

import { SOLANA_ENDPOINT } from 'config';
const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const makeOptions = (opts) => {
    return {
      autoHideDuration: opts?.autoHideDuration !== undefined ? opts.autoHideDuration : 3000,
      persist: opts?.persist !== undefined ? opts.persist : false
    };
  };

  const showInfoNotification = (title, message, options) => {
    return enqueueSnackbar({ type: 'info', title, message }, makeOptions(options));
  };

  const showErrorNotification = (msg, options) => {
    return enqueueSnackbar(
      {
        type: 'error',
        message: msg?.error?.message || msg.responseText || msg.message || msg
      },
      makeOptions(options)
    );
  };

  const showSuccessNotification = (title, message, options) => {
    return enqueueSnackbar({ type: 'success', title, message }, makeOptions(options));
  };

  const closeNotification = (id) => {
    id && closeSnackbar(id);
  };

  const showTxNotification = (description, hash, status, result) => {
    return enqueueSnackbar(
      { type: 'tx', description, hash, status, result },
      {
        autoHideDuration: 3000,
        persist: false
      }
    );
  };

  const tx = async (startNotification, endNotification, makeTx) => {
    const connection = new Connection(SOLANA_ENDPOINT, 'confirmed');
    const startTime = Date.now();
    const timeout = 60_000; // 1 minute
    try {
      const result = await makeTx();
      const signature = result.signatures ? result.signatures[0] : result.signature;
      // Wait for confirmation
      while (true) {
        if (Date.now() - startTime > timeout) {
          throw new Error('Transaction was not confirmed: timeout reached');
        }
        const statuses = await connection.getSignatureStatuses([signature]);
        const status = statuses && statuses.value[0];
        if (status) {
          const { confirmationStatus, err } = status;
          // Tx confirmed
          if (confirmationStatus === 'confirmed' || confirmationStatus === 'finalized') {
            console.log(`Transaction confirmed: ${signature}`);
            showTxNotification(endNotification, signature, 'success');
            return signature;
          }
          // Tx failed
          if (err) {
            if (err.InsufficientFundsForRent) {
              throw new Error('Transaction failed: insufficient funds');
            }
            if (err.InstructionError) {
              const [_, errorDetails] = err.InstructionError;
              if (errorDetails.Custom === 1) {
                throw new Error('Transaction failed: insufficient funds');
              }
            }
            throw new Error('Transaction failed');
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
      }
    } catch (e) {
      showErrorNotification(e.reason || e.message);
      throw e;
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        showTxNotification,
        showErrorNotification,
        showSuccessNotification,
        showInfoNotification,
        closeNotification,
        tx
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('Missing Notifications context');
  }
  const {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification,
    closeNotification,
    tx
  } = context;
  return {
    showTxNotification,
    showErrorNotification,
    showSuccessNotification,
    showInfoNotification,
    closeNotification,
    tx
  };
}
