export const WALLET_NAMES = {
  SOLANA: 'solana',
  SOLFLARE: 'solflare',
  COINBASE_SOLANA: 'coinbaseSolana',
  TONKEEPER: 'tonkeeper'
};

export const WALLET_TYPES = {
  PHANTOM: 'phantom',
  SOLFLARE: 'solflare',
  COINBASE: 'coinbase',
  TONKEEPER: 'tonkeeper'
};
