import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    liquidityInfo: {}
  },
  reducers: {
    setLiquidityInfo(state, action) {
      state.liquidityInfo = { ...action.payload };
    },
    resetLiquidityInfo(state) {
      state.liquidityInfo = {};
    }
  }
});

export const userReducer = userSlice.reducer;

export const { setLiquidityInfo, resetLiquidityInfo } = userSlice.actions;
