import React, { lazy } from 'react';

const Market = lazy(() => import('pages/Solana/Market'));
const MyTokens = lazy(() => import('pages/Solana/MyTokens'));
const BundlerTokenWindow = lazy(() => import('pages/Solana/BundlerTokenWindow'));
const Bundler = lazy(() => import('pages/Solana/Bundler'));
const CreateToken = lazy(() => import('pages/Solana/CreateToken'));
const CreateLiquidityPool = lazy(() => import('pages/Solana/CreateLiquidityPool'));

export const SOLANA_ROUTES = [
  { path: '/market', component: <Market /> },
  { path: '/myTokens', component: <MyTokens /> },
  { path: '/myTokens/:id', component: <BundlerTokenWindow /> },
  { path: '/bundler', component: <Bundler /> },
  { path: '/createToken', component: <CreateToken /> },
  { path: '/createLiquidityPool', component: <CreateLiquidityPool /> }
];
